import { MoodReadResponse, PostReadResponse, UserReadPrivateResponse, UserReadPublicResponse } from "@newstackdev/iosdk-newgraph-client-js";
import { WsEvent } from "./types";
import { uniq, upperCase } from "lodash";
import { websocketEvents } from ".";
import { cache } from "../.";
import { cachePosts } from "../actions/post";
import { cacheUsers, readUserGrants } from "../actions/user";
import { execProgressiveHandler } from "../ProgressiveHandler";
import { _current, current, win } from "../actions/auth";
import { cacheFolders, readFolderGrantees } from "../actions/folder";

console.log();

const processIncomingLive = (event: WsEvent) => {
    if (event.type != "live")
        return
    console.log("Live event received")
}

const processIncomingNewgraph = async (e: any, opts?: { isReplay: boolean }) => {
    if(
        e?.payload.message == "post_in_folder"
        &&
        e.type == "newgraph"
    ) {
        websocketEvents.emit("newgraph-notification" + (opts?.isReplay ? "-replay" : ""), e);
        await cachePosts([e.payload.post], [e.payload.folder])
    }

}

const processIncomingBadge = (ev: any, opts?: { isReplay: boolean }) => {
    if (opts?.isReplay)
        return;
    if (!(ev.type == "newgraph" && ["badge_updated", "achievement"].includes(ev.payload.message)))
        return;

    const watts = ev.payload.watts;
    // const diff = watts.new - watts.old;

    // if(!(diff > 0))
    //     return;

    websocketEvents.emit("newgraph-notification", ev);
}

const processIncomingNewcoin = (event: WsEvent) => //({ reaction, actions, state }, { msg })
// filter((_, { event: { payload } }) => (get(payload, "inbound.0.value.label") != "session")),
// (event) => 
{

    if (event.type !== "newcoin")
        return;

    const msg = event.payload.message;
    const msgCore = msg.replace(/_/, " ");
    const asMsg = {
        title: event.updated + " newcoin: " + msgCore,
        link: ``,
        description: (msg as any).error || `You ${msgCore.split(/_/)[1]} some stake.`,
        original: event,
    };

    console.log(msgCore);
};

const isAccess = (o: any) => o.value.label == "access"

const modelProcessors = {
    user: async (u: UserReadPublicResponse, event: any) => {
        await cacheUsers(u);
        // cache.user.put(u);
        const accessChanged = event.payload?.outbound?.find(isAccess) ||  event.payload?.inbound?.find(isAccess);
        if (accessChanged && (["write"].includes(accessChanged?.value?.level))) {
            await execProgressiveHandler(readUserGrants, u, { reset: true });
            if (!event.isReplay)
                websocketEvents.emit(
                    "newgraph-notification", {
                    type: "newgraph-notification",
                    payload: {
                        message: "access_granted",
                        description: "New access granted",
                        link: `/user/${u.id}/shared`
                    }
                });

            if ((event.isReplay && (event?.type == "newgraph") && (event?.payload?.message == "post_in_folder")))
                websocketEvents.emit(
                    "newgraph-notification-replay", {
                    type: "newgraph-notification",
                    payload: {
                        message: "access_granted",
                        description: "New access granted",
                        link: `/user/${u.id}/shared`
                    }
                });

        }
        else
            if (!event.isReplay)
                websocketEvents.emit("newgraph-notification", event);

        if((!event.isReplay) && (_current.value.id == u.id))
            _current.value = ({ ..._current.value, ...u }) as UserReadPrivateResponse;
            
    },
    post: async (p: PostReadResponse, event: any) => {
        if (!event.isReplay)
            websocketEvents.emit("newgraph-notification", event)

        if(event.type == "reasoning")
            p.updated = p.updated || new Date().toISOString();
        await cachePosts(p, undefined, undefined, { appendProps: event.type == "reasoning" ? ["reasoning"] : [] as any });
    },
    mood: async (m: MoodReadResponse, event: any) => {
        websocketEvents.emit("newgraph-notification", event);
        await cacheFolders({ ...event.payload.value })

        if (!event.isReplay && (event.payload?.inbound.find(isAccess) || event.payload?.outbound.find(isAccess))) {
            // await execProgressiveHandler(readUserGrants);
            await execProgressiveHandler(readFolderGrantees, { id: event.payload.value.id }, { reset: "ifnotinprogress", force: true });
            // return;
        }


        // if(event.payload.udatedProps.length <= 2)
        //     return;

        // console.log(m);

        // invalidate all items in folder?
        // force getMoods(state.api.auth.user) ?
    },
};

const processIncomingModelUpdated = async (event: WsEvent & { type: "modelUpdated" }, opts?: { isReplay: boolean }) => //({ reaction, actions, state }, { msg })
{
    if (!["modelUpdated", "reasoning"].includes(event.type))
        return;

    // const { state } = ctx;
    event.isReplay = !!opts?.isReplay;

    const model = event.model as string; //"user" ? "profile" : event.model;
    const what = capFirst(model);

    const r: any = modelProcessors[event.model] && modelProcessors[event.model](event.payload.value, event);
    if (r.then)
        await r;
    else
        await Promise.resolve()

    const inRels = event?.payload?.inbound?.filter(Boolean);
    const outRels = event?.payload?.outbound?.filter(Boolean);
    const rels: string[] = uniq<string>([
        ...(inRels || []).map((r: any) => r.value.label),
        // ...(outRels || []).map((r: any) => r.value.label)
    ]).filter(Boolean);

    if (rels.length == 1 && rels[0] === "session") return;

    const asMsg = {
        title: event.updated + " " + what + " updated",
        link: `/${event.model}/${event.payload.value.id}`,
        description: !rels.length
            ? `Your ${what.toLowerCase()} got updated: ${(event.payload.updatedProps || []).join(", ")}`
            : `${what}'s ${rels.join(", ")} got updated.`,
        original: event,
    };

    // return state.websockets.messages.activityStream.unshift(asMsg);
};

export const processIncoming = async (msg: string | object, opts?: { isReplay: boolean }) => {
    try {
        if (typeof window == "undefined")
            return;

        const ev = typeof msg == "string" ? JSON.parse(msg) : msg;

        if (ev.message == "Endpoint request timed out") {
            console.warn(ev.message)
            //actions.firebase.refreshApiToken();
            return;
        }

        websocketEvents.emit(ev.type, ev);

        // state.websockets.messages.incoming.unshift(ev)
        await processIncomingModelUpdated(ev, opts);
        processIncomingNewcoin(ev);
        processIncomingLive(ev);
        processIncomingBadge(ev, opts);
        // processIncomingAutorespond(ev, opts);
        await processIncomingNewgraph(ev, opts)

        // state.websockets.messages.activityStream.unshift({ ... })

        // ev.type === "modelUpdated"
    } catch (ex) {
        // unparseable?
    }
};

function capFirst(str: string) {
    return str[0].toUpperCase() + str.slice(1);
}

// (win as any).processIncoming = processIncoming;