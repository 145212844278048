import {
  execProgressiveHandler,
  progressiveHandler,
} from "../ProgressiveHandler";
import { QueryById } from "../types";
import { cache, EnrichedPost, newgraphClient, POST_UPLOAD_STATE_SEQUENCE, PostUploadState } from "..";
import {
  MoodReadResponse,
  PostCreateRequest,
  RatingUpdateRequest,
  PostReadResponse,
  RatingUpdateResponse,
  PostCreateResponse,
  UserReadPublicResponse,
} from "@newstackdev/iosdk-newgraph-client-js";
import { Signal, effect, signal } from "@preact/signals-react";
import { castArray, keyBy, omit, uniqBy } from "lodash";
import { attachToFolders, cachePostAttachment } from "./folder";
import { _current, current } from "./auth";
import { resizeImage } from "../../../newgraph-signals/src/utils/resizeImage"
import { retry, updateUploadStatus, uuidv4, wait } from "./upload/helpers";
export const postsQuery = (id: string | string[]) =>
  cache.post
    .where("id")
    .anyOf(id instanceof Array ? id : [id])
    .toArray();
// export const uploads = () => cache.uploads.toArray(); //.where("id").anyOf(id instanceof Array ? id : [id]).

const uploadResponseSignal = signal<PostReadResponse>({}); // as DeepSignal<PostReadResponse>;

const rateResponseSignal = signal<RatingUpdateResponse>({});

export type PostMaybeWithThumb = PostReadResponse & {
  thumbUrl?: string;
  label?: string;
} & {
  uploadState: PostUploadState
};



export const cachePosts = async (
  posts: PostReadResponse | EnrichedPost | PostReadResponse[] | EnrichedPost[], //PostMaybeWithThumb | PostMaybeWithThumb[],
  folders?: MoodReadResponse[],
  relName?: string | string[],
  opts?: { appendProps?: (keyof (EnrichedPost | PostReadResponse))[] }
) => {
  const ps = castArray(posts);

  const currList = await postsQuery(ps.map((f) => f.id || ""));
  const currDict = keyBy(currList, "id") as Record<string, PostReadResponse>;

  const items = [] as EnrichedPost[];
  // const rels = [];
  const toAttachByFolder = {} as Record<string, PostReadResponse[]>;

  // posts to update
  ps.filter((p, i) => {
    const _curr = currDict[p.id || ""];
    const curr = _curr || {};
    
    const cupd = new Date(curr.updated || 0).getTime();
    return (
      !_curr || (cupd != cupd) || (new Date(p.updated || Date.now()).getTime() >= new Date(curr.updated || 0).getTime())   
    );
  }).forEach((post) => {
    const curr = post?.id ? currDict[post?.id] : {};

    const isBoring = Object.keys(post).length == 2 && post.id && post.label;
    if (isBoring) return;

    if(opts?.appendProps?.length)
      opts?.appendProps?.forEach((p) => post[p] = (typeof (curr || {})[p] == "string" ? ((curr || {})[p] || "") as string + ((post || {})[p] || "") : post[p]) as any);

    items.push({ ...curr, ...post });
  });

  // rels to update
  items.forEach((post) => {
    const _attachTo = [...(folders || []), ...(post.moods || []), { id: post.moodId }];
    const attachTo = uniqBy(_attachTo, "id");

    attachTo.forEach((f) => {
      toAttachByFolder[f.id || ""] = [
        ...(toAttachByFolder[f.id || ""] || []),
        post,
      ];
    });
  });

  items.forEach(p => {
    if(p.contentUrl && !p.uploadState)
      return;
    if(p?.contentUrl == "processing")
      p.contentUrl = "";

    if(
      p?.uploadState?.status == "uploaded" && 
      p?.contentUrl && 
      (!p?.contentUrl?.startsWith("data:"))// &&
      // !["processing"].includes(p?.contentUrl)
    ) {
      delete (p as any).uploadState;
    }
  });


  if(!items.length)
    return;
  
  await cache.post.bulkPutDelayed(items);
  for(const fid of Object.keys(toAttachByFolder)) {
    await cachePostAttachment(toAttachByFolder[fid], [{ id: fid }], relName)
  }
  // await Promise.all(
  //   .map((folder) =>
  //     cachePostAttachment(toAttachByFolder[folder], [{ id: folder }], relName)
  //   )
  // );
};

export const cachePostsBatchAsync = batchAsync(cachePosts, { batchSize: 100, maxSecondsRetention: 10 });


// export const search = ({ query } : {}) =>
//     progressiveHandler(
//         undefined,

//         postsSearch,

//         async (progress) => {
//             const res = await newgraphClient.post.listSearchList({
//                 query,
//                 page: progress.page.toString(),
//             });
//             cache.user.bulkPut(res.data.value || []);

//             return {
//                 // ...progress,
//                 page: progress.page + 1,
//                 done: !!res.data.done
//             };
//         }
//     )

export const readPost = ({ id }: QueryById) =>
  progressiveHandler(
    { id },

    () => postsQuery(id || ""),

    async (progress, postsQuery) => {
      if (progress.done) return progress;

      const cachedItem = (await postsQuery())[0];
      if (cachedItem && cachedItem.contentUrl === "processing") return progress;

      const res = await newgraphClient.api.post.postList({ id });
      if (res.data?.id) await cachePosts(res.data as any);

      return { ...progress, done: true };
    },

    {
      autostart: true,
    }
  );



// const postCreateWaitSignal = signal({} as Record<string, { started: Date }>);
// const onPostCreate = [
//   async (folderId: string, post: PostReadResponse) => {
//     // const matches = post.content?.matchAll(/\/\W+/);

//     return Promise.resolve();
//     // // if(matches?.length)
//     // const recipient = await cache.user.get(recipientId);
//     // if(!recipient?.username)
//     //   return;
//     // postCreateWaitSignal.value = {
//     //   ...postCreateWaitSignal.value,
//     //   [`${recipient.id}/${folderId}`]: { started: new Date() }
//     // }
//   }
// ];

import { createPostSingle, createPostMultiple, UploadProgressEntry, uploadQueueSignal } from "./upload";
import { batchAsync } from "../utils/batchAsync";
export { createPostSingle, createPostMultiple };

export const xcreatePostSingle = () =>
  progressiveHandler(
    undefined,
    uploadResponseSignal,
    async (
      progress,
      uploadResponseSignal,
      postForm: PostCreateRequest & { file: any; foldersToAttach?: string[] }
    ) => {
      const shouldUpload = postForm.contentType !== "text/plain";

      POST_UPLOAD_STATE_SEQUENCE

      const f = postForm?.file;

      if (!shouldUpload && !postForm.content) {
        throw new Error(
          "post-create: Post must have either content or an attached file"
        );
      } else if (f) {
        if (!f.type) {
          throw new Error(
            "post-create: Unrecognized/unsupported content type. Upload something else."
          );
        }
      }

      if (f && f?.type) postForm.contentType = f.type;

      const purePost = omit(postForm, ["file", "foldersToAttach", "localId"]);

      const provisionalId = purePost?.id || uuidv4(); //"prov-post-" + Date.now();
      const now = new Date().toISOString();

      const contentType = postForm.contentType || "text/plain";

      const base64thumb = f && (await resizeImage(f));

      const provisionalPost: EnrichedPost = {
        id: provisionalId,
        ...purePost,
        thumbUrl: f?.preview,
        contentUrl: f ? base64thumb : "",
        label: "post" as "post",
        author: await execProgressiveHandler(current) as UserReadPublicResponse,
        updated: now,
        created: now,
        ...(contentType == "text/plain" ? { contentType } : {}),
        uploadState: {
          blob: f?.originFileObj,
          filename: f?.name,
          thumb: base64thumb,
          done: false,
          status: "preparing"
        } as PostUploadState
      };

      if (f && !f.thumbUrl) f.thumbUrl = provisionalPost?.uploadState?.thumb;

      const foldersToAttach = postForm.foldersToAttach?.map((f) => ({ id: f }));

      // uploadStatus: preparing
      await cachePosts(provisionalPost as EnrichedPost, foldersToAttach);

      const testread = await cache.post.get(provisionalId);
      console.log(testread)
      // debugger;
      // const provisionalEdge = await cachePostAttachment([provisionalPost], postForm.foldersToAttach?.map(id => ({ id })) || [])

      await wait(3); 

      const p = await retry(
        () => newgraphClient.api.post.postCreate({
          ...purePost,
          contentUrl: "preparing",
          id: provisionalId,
        } as any)
      );

      updateUploadStatus("created", provisionalPost, foldersToAttach);

      // for(const extraJob of onPostCreate)
      //   await extraJob(postForm.foldersToAttach![0], provisionalPost)
      await wait(3); 

      await retry(() =>
        attachToFolders(
          { ...(p.data as any) },
          (postForm.foldersToAttach || []).map((fid) => ({ id: fid }))
        )
      );

      updateUploadStatus("attached", provisionalPost, foldersToAttach);

      // throw new Error("fuck")
      // await wait(3); 

      // } catch (ex) {
      //   console.log(ex);
      //   debugger;
      // }

      if (!provisionalId) console.error("Upload debug error: Post has no id")
      if (!p.data.id) console.warn("Upload debug warning: Created post has no id")

      if (f) {
        const uploadInfo = await retry(() =>
          newgraphClient.api.post.uploadCreate({
            filename: f.name,
            targetId: provisionalId, //p.data.id as string,
            contentType: f.type,
          }));

        // if (!purePost.id)
        
        await updateUploadStatus("upload-requested", provisionalPost, foldersToAttach );
        
  
          // await cachePosts({
          //   ...provisionalPost,
          //   ...p.data,
          //   contentUrl: "processing",
          // });
        await wait(3); 

        const r = await retry(() => {
          return fetch(uploadInfo.data.url as string, {
            method: "PUT",
            body: f,
          });
        });

        if (r.status != 200) {
          progress.error = new Error(
            `post-create: The post was created but couldn't upload the file, error: ${await r.json()}`
          );
        }

        await updateUploadStatus("uploaded", { ...provisionalPost }, foldersToAttach );

      }

      const doNextJob = () => {
        uploadQueueSignal.value = [...uploadQueueSignal.value.slice(1)];

        const nextJob = uploadQueueSignal.value[0];
        nextJob && uploader.value.exec(nextJob); // semaphore will prevent duplicate execution
      };

      setTimeout(doNextJob);

      return progress;
    }
  );

const [_, uploader] = createPostSingle();

// type ContentMode = "last" | "first" | "each";
// // export const xcreatePostMultiple = () =>
// //   progressiveHandler(
// //     undefined,
// //     uploadQueueSignal,
// //     async (
// //       progress,
// //       uploadQueueSignal,
// //       postForm: PostCreateRequest & {
// //         files: any[];
// //         foldersToAttach?: [];
// //         contentMode: ContentMode;
// //       }
// //     ) => {
// //       const fs = [...(postForm.files || [])];
// //       const last = fs.pop();

// //       const tmplt = (f?: any, content?: string) => ({
// //         localId: Date.now().toString(),
// //         file: f,
// //         contentUrl: "",
// //         content: content || "",
// //         progress: "scheduled",
// //         foldersToAttach: [...(postForm.foldersToAttach || [])],
// //       });

// //       const cm = postForm.contentMode;
// //       const byContentMode = (i: number) =>
// //         cm == "each" ||
// //           (cm == "first" && i == 0) ||
// //           (cm == "last" && i == fs.length - 1)
// //           ? postForm.content
// //           : "";

// //       const queue = fs.map(
// //         (f, i) =>
// //           ({
// //             ...tmplt(f, byContentMode(i)),
// //           }) as UploadProgressEntry
// //       );

// //       queue.push({
// //         ...tmplt(last),
// //         content: postForm.content || "",
// //       } as UploadProgressEntry);
      
// //       uploadQueueSignal.value = [...uploadQueueSignal!.value, ...queue];

// //       uploader.value.exec(uploadQueueSignal.value[0]); // semaphore will prevent duplicate execution

// //       return { ...progress, done: false };
// //     }
// //   );

export const deletePost = () =>
  progressiveHandler({}, null, async (progress, __, { id }: { id: string }) => {
    debugger;
    const currp = await cache.post.get(id);
    if (id && (!currp?.contentUrl || (currp?.contentUrl != "preparing"))) {
      await newgraphClient.api.post.postDelete({ id });
      await cachePosts([{ id, deleted: true }] as any);
    }
    else
      await cache.post.delete(id)
    return progress;
  });

// const res: PromiseSettledResult<PostReadResponse>[] = await Promise.allSettled(promises);
// const p = res[0];
// console.log(res);

// const successes = res.filter((r) => r.status != "rejected");

// const whenDone = "files were uploaded successfully and are being processed.";
// const message =
//     res.length == 1
//         ? successes.length
//             ? "File was uploaded successfully and is being processed."
//             : "File upload failed"
//         : successes.length == res.length
//             ? `All ${res.length} ${whenDone}`
//             : `${successes.length} out of ${res.length} ${whenDone}`;

// effects.ux.notification.open({ message });

// export const rate: Action<{
//     post: PostReadResponse;
//     amount: number;
//     contextType: string;
//     contextValue: string;
//     messageWrapper?: (string, RatingUpdateResponse) => any;
// }> = pipe(
//     // mood?: MoodReadResponse
//     debounce(300),
//     async ({ state, actions, effects }: Context, { post, amount, contextType, contextValue, messageWrapper }) => {
//         const t = post.title || post.content || "";
//         const mt = t.length <= 30 ? t : t.substring(0, 30) + "...";
//         try {
//             const res = await state.api.client.post.rateCreate({
//                 targetId: post.id,
//                 value: amount || 1,
//                 ...(contextType ? { contextType, contextValue } : {}),
//             });

//             const err = maxNTimes((res.data as any)?.error?.toString());

//             const msgTxt = `You voted ${amount}% ${err}`;
//             const msg = messageWrapper ? messageWrapper(msgTxt, res.data) : msgTxt;
//             effects.ux.message.info(msg, 10);
//         } catch (ex) {
//             effects.ux.message.error(((ex as any).error as ErrorResponse).errorMessage);
//         }
//     },
// );

const rateQueue: any[] = [];
let rateConcurrencyLock = 0;
const processRateQueue = async () => {
  if (rateConcurrencyLock || !rateQueue.length) return;
  rateConcurrencyLock = 1;

  const { postForm, rateSignal } = rateQueue.pop();
  const res = await newgraphClient.api.post.rateCreate(postForm);

  rateSignal.value = res.data;

  await cache.post.update(postForm.targetId!, { vote: postForm.value });

  rateConcurrencyLock = 0;
  processRateQueue();
};

export const rate = ({ targetId }: { targetId: string }) =>
  progressiveHandler(
    { targetId },
    rateResponseSignal,
    async (progress, rateSignal, postForm: RatingUpdateRequest) => {
      if (!postForm.targetId) return progress;

      const { targetId, value } = postForm;

      rateQueue.push({
        postForm: {
          targetId,
          value,
        },
        rateSignal,
      });
      processRateQueue();

      await Promise.resolve();
      // const res = await newgraphClient.api.post.rateCreate();
      // rateSignal.value = res.data;

      // await cache.post.update(postForm.targetId!, { vote: postForm.value });

      return {
        ...progress,
      };
    }
  );

// export const getRemoteMeta: Action<{ url: string }, PostRemoteMetaProxyResponse> = async ({ state }, { url }) => {
//     const res = await state.api.client.post.utilsRemoteMetaProxyList({
//         url: url,
//     });
//     return res.data;
// };

export const getRemoteMeta = async ({ url }: { url: string }) => {
  const res = await newgraphClient.api.post.utilsRemoteMetaProxyList({
    url: url,
  });
  return res.data;
};

