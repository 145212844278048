
import { signal } from "@preact/signals-react";
import { semaphore, defaultProgress } from "./progress";
import { ProgressEntrySignal, ProgressiveHandler, ProgressiveHandlerResponse } from "./types";
import { ProgressEntry } from "./progressEntry";

const defaultSignal = signal<any>(defaultProgress("", { done: true }));

const isSsr = typeof window == "undefined";

type ProgressiveHandlerInstance<T,S> = (params: T) => ProgressiveHandlerResponse<S>

/*
    wraps execution of a progressiveHandler (as below) for imperative contexts
*/
export const execProgressiveHandler = async <T,S>(ph: ProgressiveHandlerInstance<T,S>, params?: T, opts?: { force?: boolean, reset?: boolean | "ifnotinprogress", exec?: boolean, withProgress?: boolean }) => {
    const [_cache, progress] = ph(params || {} as T);
    if(progress.value.inProgress && progress.value.promise) {
        await progress.value.promise;
    }

    if(opts?.reset && !(progress.value.inProgress && (opts?.reset == "ifnotinprogress"))) {
        progress.value.reset();

        console.log("Resetting progressive handler")
    }
    if(!progress.value.autostart || opts?.force) {
        // !progress.value.autostart
        progress.value.exec(params);
    }
    progress.value.inProgress && (await progress.value.promise);
    const [_updadedCache, _] = ph(params || {} as T);

    const cache = await ((typeof _updadedCache == "function") ? (_updadedCache as Function)() : Promise.resolve(_updadedCache));

    if(opts?.withProgress)
        return [cache, _];
    
    return cache as S;// ReturnType<typeof ph>;
}

export const progressiveHandler: ProgressiveHandler = (params, cache, reader, init) => {
    // if (params && !params?.id)
    //     return [cache, defaultSignal]; 
    // throw new Error("Bad query"); //return Promise.resolve(undefined);
    // if(isSsr)
    //     return [{}, defaultSignal] as any;

    const progressEntrySignal = semaphore({
        key: [reader.toString().replace(/[\n\r]/gm, " "), JSON.stringify(params)].join("+"),
        callback: (progress, params) => reader(progress, cache, params),
        init
    });
    
    return [cache, progressEntrySignal]
};

