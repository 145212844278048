import { ProgressEntry } from "./progressEntry";
import { Signal, signal } from "@preact/signals-react";
import { ProgressCallback, ProgressEntrySignal } from "./types";

export type SemaphoreParams = {
  key: string;
  callback: (progress: ProgressEntry, params?: unknown) => Promise<Partial<ProgressEntry>>,
  init?: Partial<ProgressEntry>
}
type ProgressSignalsTracker = Record<string, Signal<any>>

const stats = {
  prevented: 0
};

const progress: ProgressSignalsTracker = {};

export const defaultProgress =
  (key: string = "", init: Partial<ProgressEntry> = {}) =>
    new ProgressEntry(key, init || {});

const wait = (milliseconds: number) => new Promise((res) => setTimeout(res, milliseconds));
const timeDiff = (a: string | Date, b: string | Date) => new Date(a).getTime() - new Date(b).getTime();

const _doJob = (callback: SemaphoreParams['callback']) =>
  (sg: Signal<ProgressEntry>, params: unknown) => {
    let val = sg.value;
    if (val.inProgress && ((val.maxConcurrency || 0) > 0) && ((val._concurrency || 0) >= val.maxConcurrency)) {
      // console.log("Parallel executions prevented: ", ++stats.prevented)
      return sg;
    }
    if (val.paused) {
      // console.log("Progress paused: ", ++stats.prevented)
      return sg;
    }
    if (val.throttle?.leading) {
      const t = val.throttle || {};
      const now = new Date().toISOString();
      const lc = t._lastCalled || now;
      const elapsed = timeDiff(now, lc);
      if ((elapsed < val.throttle?.leading) && !(t.maxWait && (elapsed > t.maxWait))) {
        t._lastCalled = now;
        return sg;
      }
    }

    val.inProgress = true;
    val._concurrency++;

    const exec = async () => {
      await wait(val.throttle?.leading || 0);

      try {
        const res = await callback(sg.value, params);

        sg.value = sg.value.clone({
          ...res,
          promise: undefined,
          inProgress: false,
          paused: true,
          executions: sg.value.executions + 1,
          _concurrency: val._concurrency - 1
        });

        await wait(val.throttle?.trailing || 0)
      } catch (error) {
        // debugger;
        sg.value = sg.value.clone({
          error: error as Error,
          promise: undefined,
          inProgress: false,
          paused: true
        });
        console.error(error);

      } finally {
      }
      return sg.value;
    }

    const p = exec();

    sg.value = sg.value.clone({ promise: p }); //.promise = p;


    // retriable 
    // const xpromise = new XPromise<ReturnType<typeof ph>>();

    // const executionTracker = {
    //   startTime: Date.now(),
    //   promiseResolved: false,
    //   int: setInterval(() => {
    //     if (executionTracker.promiseResolved) {
    //       clearInterval(executionTracker.int!);
    //       return;
    //     }

    //     if (Date.now() - executionTracker.startTime < 10000)
    //       return;

    //     sg.value.reset();


    //     // exec();
    //   }, 1000),
    //   xpromise
    // };

    return sg;
  }

export const semaphore = ({ key, callback, init }: SemaphoreParams, params?: unknown) => {
  const doJob = _doJob(callback);

  if (!progress[key]) {
    const onContinue: ProgressCallback = (pe, params) => {
      const val = sg.value;

      // if (sg.value.inProgress || sg.value.done)
      if (val.done || (val.inProgress && ((val.maxConcurrency || 0) > 0) && ((val._concurrency || 0) >= val.maxConcurrency)))
        return;

      sg.value = sg.value.clone({ paused: false });
      return doJob(sg, params);
    };

    const onReset: ProgressCallback = (pe) => {
      const p = new ProgressEntry(key, { ...init, onContinue, onReset });

      if (init?.onReset) init?.onReset(p)

      return p;
    }

    const pe = new ProgressEntry(key, { ...init, onContinue, onReset });

    // console.log("Creating signal for ", key.substring(0, 20));
    progress[key] = signal(pe) //defaultProgress(key));
  }

  const sg = progress[key] as ProgressEntrySignal;

  return sg.value.autostart ? doJob(sg, params) : sg;
};


